<template>
    <div
      v-bind:class="{
        'react-code-input-container': true,
        [className]: !!className
      }"
      v-bind:style="{ width: `${fields * fieldWidth}px` }"
    >
      <form autocomplete="off">
        <p class="title" v-if="title">{{title}}</p>
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
          <div class="react-code-input" style="white-space: nowrap;">
            <template v-for="(v, index) in values">
              <input
                :key="`${id}-${index}`"
                role="presentation"
                :type="type === 'number' ? 'tel' : type"
                :pattern="type === 'number' ? '[0-9]' : null"
                :autoFocus="autoFocus && !loading && index === autoFocusIndex"
                :style="{
                  width: `${fieldWidth}px`,
                  height: `${fieldHeight}px`
                }"
                :data-id="index"
                :value="v"
                :ref="iRefs[index]"
                v-on:input="onValueChange"
                v-on:focus="onFocus"
                v-on:keydown="onKeyDown"
                autocomplete="new-password"
                :disabled="disabled"
                :required="required"
                maxlength="1"
              />
            </template>
          </div>
          <div style="margin-left: 15px;">
              <button type="button" class="button-71" @click="pasteValue">
                Paste
              </button>
          </div>
        </div>
      </form>
      <div v-if="loading" class="loading" :style="{lineHeight: `${fieldHeight}px`}">
        <div class="blur" />
        <svg
          class="spin"
          viewBox="0 0 1024 1024"
          data-icon="loading"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill="#006fff"
            d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"
          />
        </svg>
      </div>
    </div>
  </template>
  
  <script>
  const KEY_CODE = {
    backspace: 8,
    left: 37,
    up: 38,
    right: 39,
    down: 40
  }; 
  export default {
    name: "CodeInput",
    props: {
      type: {
        type: String,
        default: "number"
      },
      className: String,
      fields: {
        type: Number,
        default: 6
      },
      fieldWidth: {
        type: Number,
        default: 58
      },
      fieldHeight: {
        type: Number,
        default: 54
      },
      autoFocus: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      title: String,
      change: Function,
      complete: Function,
      loading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      const { fields, values } = this;
      let vals;
      let autoFocusIndex = 0;
      if (values && values.length) {
        vals = [];
        for (let i = 0; i < fields; i++) {
          vals.push(values[i] || "");
        }
        autoFocusIndex = values.length >= fields ? 0 : values.length;
      } else {
        vals = Array(fields).fill("");
      }
  
      this.iRefs = [];
      for (let i = 0; i < fields; i++) {
        this.iRefs.push(`input_${i}`);
      }
  
      this.id = +new Date();
      return { values: vals, autoFocusIndex };
    },
    mounted() {
      this.focusInput();
    },
    methods: {
      focusInput(){
        this.iRefs[0].focus();
      },
      pasteValue(){
        navigator.clipboard.readText().then((textClipboard) => {
          if(textClipboard && /^\d+$/.test(textClipboard)) 
          {
            if(textClipboard.length > 6) textClipboard = textClipboard.substring(0, 6);
            let finalStr = String(parseInt(textClipboard)).padEnd(6, '0');
            this.values = Object.assign([], finalStr);
          }
          this.$nextTick(() => {              
            this.triggerChange();
            this.$forceUpdate();
          });
        }).catch((error) => {
          console.error(error);
        }); 
      },
      onFocus(e) {
        e.target.select(e);
      },
      onValueChange(e) {
        const index = parseInt(e.target.dataset.id);
        const { type, fields } = this;
        if (type === "number") {
          e.target.value = e.target.value.replace(/[^\d]/gi, "");
        }
        // this.handleKeys[index] = false;
        if (
          e.target.value === "" ||
          (type === "number" && !e.target.validity.valid)
        ) {
          return;
        }
        let next;
        const value = e.target.value;
        let { values } = this;
        values = Object.assign([], values);
        if (value.length > 1) {
          let nextIndex = value.length + index - 1;
          if (nextIndex >= fields) {
            nextIndex = fields - 1;
          }
          next = this.iRefs[nextIndex];
          const split = value.split("");
          split.forEach((item, i) => {
            const cursor = index + i;
            if (cursor < fields) {
              values[cursor] = item;
            }
          });
          this.values = values;
        } else {
          next = this.iRefs[index + 1];
          values[index] = value;
          this.values = values;
        }
  
        if (next) {
          const element = this.$refs[next][0];
          element.focus();
          element.select();
        }
  
        this.triggerChange(values);
      },
      onKeyDown(e) {
        const index = parseInt(e.target.dataset.id);
        const prevIndex = index - 1;
        const nextIndex = index + 1;
        const prev = this.iRefs[prevIndex];
        const next = this.iRefs[nextIndex];
        switch (e.keyCode) {
          case KEY_CODE.backspace: {
            e.preventDefault();
            const vals = [...this.values];
            if (this.values[index]) {
              vals[index] = "";
              this.values = vals;
              this.triggerChange(vals);
            } else if (prev) {
              vals[prevIndex] = "";
              this.$refs[prev][0].focus();
              this.values = vals;
              this.triggerChange(vals);
            }
            break;
          }
          case KEY_CODE.left:
            e.preventDefault();
            if (prev) {
              this.$refs[prev][0].focus();
            }
            break;
          case KEY_CODE.right:
            e.preventDefault();
            if (next) {
              this.$refs[next][0].focus();
            }
            break;
          case KEY_CODE.up:
          case KEY_CODE.down:
            e.preventDefault();
            break;
          default:
            // this.handleKeys[index] = true;
            break;
        }
      },
      triggerChange(values = this.values) {
        const { fields } = this;
        const val = values.join("");
        this.$emit("change", val);
        if (val.length >= fields) {
          this.$emit("complete", val);
        }
      }
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .react-code-input-container {
    position: relative;
  }
  
  .react-code-input > input {
    border: solid 1px #a8adb7;
    border-right: none;
    font-family: "Lato";
    font-size: 20px;
    color: #525461;
    text-align: center;
    box-sizing: border-box;
    border-radius: 0;
    -webkit-appearance: initial;
  }
  
  .react-code-input > input:last-child {
    border-right: solid 1px #a8adb7;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  
  .react-code-input > input:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  
  .react-code-input > input:focus {
    outline: none;
    border: 1px solid #006fff;
    caret-color: #006fff;
  }
  
  .react-code-input > input:focus + input {
    border-left: none;
  }
  
  .loading {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
  }
  
  .blur {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.5;
    filter: blur(0.5px);
    transition: opacity 0.3s;
  }
  
  .title {
    margin: 0;
    height: 20px;
    padding-bottom: 10px;
  }
  
  .spin {
    display: inline-block;
    animation: loadingCircle 1s infinite linear;
  }
  
  @keyframes loadingCircle {
    100% {
      transform: rotate(360deg);
    }
  }
/* CSS */
.button-71 {
  background-color: #0078d0;
  border: 0;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
  font-size: 18px;
  font-weight: 600;
  outline: 0;
  padding: 14px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-71:before {
  background-color: initial;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 125px;
  content: "";
  height: 50%;
  left: 4%;
  opacity: .5;
  position: absolute;
  top: 0;
  transition: all .3s;
  width: 92%;
}

.button-71:hover {
  box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .button-71 {
    padding: 14px 20px;
  }
}
  </style>